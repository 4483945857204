import TableAbstract from './table_abstract';
import moment from "moment";
import wNumb from '../wNumb';
import $ from 'jquery'
import { messageTranslations } from '../library_translation';

export default class YearlySituationTable extends TableAbstract {
  dataTableOptions() {
    const moneyOptions = {
      thousand: ' ',
      suffix: ' €',
      decimals: 0,
    };
    const moneyFormatNoDecimals = new wNumb(moneyOptions);
    const { source } = this.tableOptions;
    return {
      paging: false,
      lengthChange: false,
      info: false,
      searching: false,
      language: messageTranslations(),
      autoWidth: true,
      colResize: false,
      deferRender: true,
      processing: true,
      data: source,
      columns: this.positionColumns,
      order: [[ 0, "desc" ]],
      columnDefs: [
        { "orderable": false, "targets": [1] },
        {
          render: function(data, type, row, meta) {
            if ($.isNumeric(data) && (type === 'display' || type === 'filter')) {
              var moneyString = moneyFormatNoDecimals.to(data / 100);
              return this.replaceMoneyCurrency(moneyString, '€', row.currency);
            } else if (typeof data === 'boolean') {
              return
            } else {
              return $.fn.dataTable.render.text().display(data, type, row, meta);
            }
          }.bind(this), targets: '_all',
        }
      ],
    }
  }

  get positionColumns() {
    const className = 'align-left'
    return [
      { data: 'year',
        title: I18n.t("activerecord.attributes.past_situation.year"),
        className: 'all align-left',
        render: $.fn.dataTable.render.text()
      },
      {
        data: 'date',
        title: I18n.t("activerecord.attributes.past_situation.last_date"),
        className: className,
        type: 'date',
        render: function(data, type, row){
          return moment(data, 'YYYY/MM/DD').format('DD/MM/YYYY');
        }
      },
      {
        data: 'net_deposit_cents',
        title: I18n.t("activerecord.attributes.past_situation.net_deposit"),
        className: className
      },
      {
        data: 'performance_ytd',
        title: I18n.t("activerecord.attributes.past_situation.performance_ytd"),
        className: className,
        render: function ( data, type, row ) {
          if ( type === 'display' ) {
            return data + "%"
          }
          return data;
        }
      }
    ]
  }

  replaceMoneyCurrency(moneyString, fromCurrencySymbol, toCurrencySymbol) {
    return moneyString.replace(fromCurrencySymbol, toCurrencySymbol);
  }
}
