import { Controller } from "stimulus";
import Highcharts from "highcharts";
import HighchartsPatternFill from 'highcharts/modules/pattern-fill';
import ChartFactory from "../utilities/product_sheet_charts/chart_factory";

export default class extends Controller {
  static values = {
    chartOptions: Object,
    chartName: String
  }

  initialize() {
    HighchartsPatternFill(Highcharts);
    this.overrideContrastFunction();
    this.chart = null;
  }

  async connect() {
    try {
      const chart = await ChartFactory.createChart(this.chartNameValue, this.chartOptionsValue);
      const chartOptions = chart.highchartsOptions();

      if (!this.element.hasChildNodes()) {
        this.chart = Highcharts.chart(this.element, chartOptions);
      }
    } catch (error) {
      console.error('Error initializing chart:', error)
    }
  }

  disconnect() {
    if(this.chart){
      this.chart.destroy();
    }
  }
  overrideContrastFunction() {
    console.log("Overwrite called")
    Highcharts.Renderer.prototype.getContrast = function (rgba) {
      rgba = Highcharts.Color(rgba).rgba;
      return rgba[0] + rgba[1] + rgba[2] > 700 ? '#000000' : '#FFFFFF';
    };
  }
}
