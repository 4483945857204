import ChartAbstract from './chart_abstract';
import { numberFormat, dateFormat } from "highcharts";

export default class PastPerformanceChart extends ChartAbstract {
  highchartsOptions() {
    const { year } = this.chartOptions;
    return {
      chart: {
        height: 200
      },
      legend: {
        enabled: true
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      series: this.series,
      xAxis: {
        type: 'category',
        labels: {
          formatter() {
            return dateFormat('%b', Date.UTC(year, this.value, 0));
          },
          step: 1
        },
        min: 1,
        max: 12,
        crosshair: true
      },
      yAxis: [{ // Primary yAxis
        title: {
          text: '',
        },
        labels: {
          format: '{value} %'
        }
      }],
      tooltip: {
        shared: true,
        headerFormat: '',
      }
    }
  }

  get series() {
    let seriesOptions = [];
    const { data } = this.chartOptions;
    let index = 0;
    for (const [name, subdata] of Object.entries(data)) {
      if (name === "performance") {
        seriesOptions[index] = {
          name: I18n.t("product_sheet.past_situations.chart.series.monthly_performance"),
          data: subdata,
          color: positiveColor,
          negativeColor: negativeColor,
          type: 'column',
          zIndex: 1,
          tooltip: {
            pointFormat: '<br><span>{series.name}</span>: <b>{point.y}%</b>'
          }
        };
      } else if (name === "performance_ytd") {
        seriesOptions[index] = {
          name: I18n.t("product_sheet.past_situations.chart.series.performance_ytd"),
          data: subdata,
          color: textColor,
          type: 'line',
          zIndex: 2,
          tooltip: {
            pointFormatter: function() {
              let str = '<br><span>' + this.series.name + '</span>: <b>' + this.y + '%</b><br><span>';
              str += I18n.t("product_sheet.past_situations.chart.series.net_deposit");
              str += '</span>: <b>' + numberFormat(this.net_deposit, 0) + '</b><br><span>';
              return str;
            }
          }
        };
      }
      index++;
    }
    return seriesOptions
  }
}
