import ChartAbstract from './chart_abstract';
import Highcharts from "highcharts";

const basicChartOrderedKeys = ["main_residence", "real_estate", "family_business", "company"]
export default class BasicChart extends ChartAbstract {
  highchartsOptions() {
    const { display } = this.chartOptions;
    return {
      chart: {
        type: 'pie',
        height: 250,
        style: {
            fontFamily: 'inherit'
        }
      },
      title: {
        text: '',
        align: 'left',
        style: {
            "color": textColor,
            "fontSize": "16px",
            "textTransform": "uppercase"
        }
      },
      xAxis: {
          showEmpty: false
      },
      yAxis: {
        showEmpty: false,
        title: { text: ''}
      },
      plotOptions: this.plotOptions,
      tooltip: this.chartToolTipOptions(this.unit),
      credits: { enabled: false },
      series: [{
          name: '',
          data: this.series,
          showInLegend: true,
          innerSize: '50%'
      }],
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign:'middle',
        width: 150,
        itemWidth: 150,
        itemMarginTop: 5,
        itemMarginBottom: 10,
        symbolHeight: 15,
        symbolRadius: 3,
        useHTML: true,
        enable: display === 'legend',
        itemStyle: {
            lineHeight:"13px",
            fontWeight: 'normal',
            textOverflow: null
        }
      }
    }
  }

  get plotOptions() {
    return {
      pie: {
        shadow: false,
        center: ['50%', '50%'],
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
            enabled: true,
            ...this.labelFormat,
            style: {
              fontWeight: "bold",
              color: "contrast",
            }
        }
      },
      bar: {
        shadow: false,
        center: ['50%', '50%'],
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
            enabled: true,
            format: '{point.pc:.1f}%',
            style: {
              fontWeight: "bold",
              color: "contrast",
            }
        }
      },
      series: { allowPointSelect: true }
    }
  }

  get labelFormat() {
    const { display } = this.chartOptions;
    if (display === 'legend') {
      return {
        format: '{point.percentage:.1f}%',
        distance: -30,
      }
    } else {
      return {
        format: '<b>{point.name}</b>: {point.y:,.0f}€ / {point.percentage:.1f}%',
        distance: 5
      }
    }
  }

  get series() {
    const { data } = this.chartOptions;
    var serie = [], dataLen, i;
    var dataSum = 0;
    dataLen = Object.keys(data).length;
    if (dataLen > 0) {
      dataSum = Object.values(data).reduce(this.add);
    }
    // add index to be able to priorize points in serie
    var sortedData = this.sortData(data)

    for (i = 0; i < dataLen; i++) {
        var key = Object.keys(sortedData)[i];
        var index = this.seriePointIndex(key, i);
        var val = parseFloat(Object.values(sortedData)[i])
        serie.push({
            index: index,
            name: this.nameI18N(key),
            y: val,
            pc: val*100/dataSum
        });
    }
    // serie = serie.sort(this.orderSeries)
    // sort serie by index
    serie = serie.sort((a, b) => (a.index > b.index) ? 1 : -1);
    // add className
    for(let serieIndex = 0; i < serie.length; serieIndex++){
      serie[i].className = "highcharts-color-"+ i
    }
    return serie
  }
  sortData(o) {
    return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});
  }
  seriePointIndex(key, i) {
    // get the number of points to be ordered in priority, to reserve these indexes for ordering
    var orderedItemsSize = basicChartOrderedKeys.length
    if (key == "main_residence") {
      return 0
    } else if (key == "real_estate") {
      return 1
    } else if (key == "family_business") {
      return 2
    } else if (key == "company") {
      return 3
    } else {
      return i + orderedItemsSize
    }
  }
  nameI18N(key) {
    if (basicChartOrderedKeys.includes(key)) {
      return I18n.t("chart.serie_point."+key)
    } else {
      return key
    }
  }
  chartToolTipOptions(unit, shared) {
      shared = shared || true;
      if (unit === "percentage") {
          return {
              pointFormat: '<b>{point.y}</b>',
              valueSuffix: '%',
              valueDecimals: 1,
              shared: shared
          }
      } else if (unit === "value") {
          return {
              pointFormat: '<b>{point.y}</b>',
              valueSuffix: ' €',
              valueDecimals: 0,
              shared: shared
          }
      } else {
          return {
              pointFormat: '<b>{point.y}</b>',
              valueSuffix: '',
              valueDecimals: 0,
              shared: shared
          }
      }
  }
  ordereNumAlpha(a, b) {
    return /[A-Za-z]/.test(a) - /[A-Za-z]/.test(b) || (a.toUpperCase() < b.toUpperCase() ? -1 : a.toUpperCase() > b.toUpperCase() ? 1 : 0)
  }
  add(a, b) {
    return a + b;
  }
}
